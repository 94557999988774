<template>
  <fragment>
    <audio ref="jingleBells">
      <source :src="require('./assets/we-wish-you-a-merry-christmas-127995.mp3')" />
    </audio>
    <v-dialog class="d-flex align-center justify-center" fullscreen :value="!activated">
      <div class="d-flex align-center pa-2 justify-center" id="cover">
        <v-card style="background-image: url('./assets/images/bg.jpg'); width: calc(95vw - 25px); height: calc(90vh - 25px);" class="ma-2 elevation-6 flex-grow-1 flex-shrink-0">
          <div style="position: relative; width: 100%; height: 100%" class="d-flex flex-column align-center justify-center text-center">
            <img :src="require('./assets/images/bell2.png')" />
            <v-btn class="d-block" color="#aa1039" dark @click="openInvite">Buka Undangan</v-btn>
          </div>
        </v-card>
      </div>
    </v-dialog>
    <div id="wrapper" v-if="activated">
      <transition name="zoom-from-top-left" appear>
        <img class="snowflakes animation-top-left" id="snowflake1" :src="require('./assets/images/top-left.png')" />
      </transition>
        <transition name="zoom-from-top-right" appear>
          <img class="snowflakes animation-top-right" id="snowflake2" :src="require('./assets/images/top-center.png')" />
        </transition>
      <transition name="zoom-from-bottom-left" appear>
        <img class="bottom-image animation-bottom-left" id="bottomLeft" :src="require('./assets/images/bottom-left.png')"/>
      </transition>
      <transition name="zoom-from-bottom-right" appear>
        <img class="bottom-image animation-bottom-right" id="bottomRight" :src="require('./assets/images/bottom-right.png')"/>
      </transition>
      <img class="centered" id="bell" :src="require('./assets/images/bell.png')" />

      <div id="textLifter">
        <transition name="text-animation" @after-enter="stagger++" appear>
          <p v-if="(stagger >= 1)" class="undangan">Undangan</p>
        </transition>
        <transition name="text-animation" @after-enter="stagger++">
          <h1 v-if="(stagger >= 2)">Perayaan Natal</h1>
        </transition>
        <transition name="text-animation" @after-enter="stagger++">
          <p v-if="(stagger >= 3)" class="location">GKBI Ekklesia Kristus Karombasan</p>
        </transition>
      </div>
      <transition name="text-animation" @after-enter="stagger++">
        <div id="tanggalWrapper" v-if="(stagger >= 4)">
          <div id="tanggal">
            <div id="bigNumber">13</div>
            <div style="position: static">
              Desember<br />
              18&colon;00&nbsp;WITA
            </div>
          </div>
          <div class="text-center" id="buttons" style="position:static">
            <v-btn small class="mt-3" color="#aa1039" dark href="https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=Mzc3cDY0bDRsZjNnb2tmYzNqcmVwMnN2NTkgNGI3NGYwOTRiYzY5NWFkMWUyNTQ2ZDk3NzgwMTAyZGZlNDk4ZWJhN2Q0Y2FlZmNlMWQxY2Y1NWRmNTQxNDBmNUBn&tmsrc=4b74f094bc695ad1e2546d97780102dfe498eba7d4caefce1d1cf55df54140f5%40group.calendar.google.com">
              <v-icon left>mdi-calendar</v-icon>
              Tambahkan ke Kalender Saya
            </v-btn>
          </div>
          <!-- <div id="buttons" style="position: static">
            <v-btn class="my-3" color="#aa1039" dark small>
              <v-icon left>mdi-google-maps</v-icon>
              Petunjuk Lokasi
            </v-btn>
            <v-btn color="#aa1039" dark small>
              <v-icon left>mdi-calendar-plus</v-icon>
              Tambah ke kalender saya
            </v-btn>
          </div> -->
        </div>
      </transition>
      <transition name="text-animation" @after-enter="stagger++">
        <div v-if="(stagger >= 5)" id="place">
          Tempat:<br />
          GKBI Ekklesia Kristus Karombasan
        </div>
      </transition>
    </div>
  </fragment>
</template>

<script>
export default {
  name: 'Christmas',
  data () {
    return {
      activated: false,
      stagger: 0,
      timerId: null
    }
  },
  mounted () {
    this.$data.timerId = setTimeout(() => { this.$data.stagger++ }, 1000)
  },
  beforeDestroy () {
    clearTimeout(this.$data.timerId)
    this.$data.timerId = null
  },
  methods: {
    openInvite () {
      this.$data.activated = true;
      this.$refs.jingleBells.play()
    }
  }
}
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&family=Rakkas&display=swap');

  #cover {
    position: static;
    width: 100%;
    height: 100%;
    background-color: white;
  }

  #cover img {
    position: static;
    width: 125px;
    display: block;
    object-fit: contain;
  }

  div,img {
    position: absolute;
    font-family: 'Montserrat', sans-serif;
    color: #aa1039;
  }

  #textLifter {
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  #textLifter * {
    text-align: center;
    width: 100%;
    position: absolute;
  }

  #tanggalWrapper {
    margin-top: -3px;
    position: absolute;
    top: 47.5%;
    width: 100%;
    z-index: 9
  }
  
  #tanggal {
    align-items: center;
    position: static;
    font-weight: 600;
    border-top: 2px solid #aa1039;
    border-bottom: 2px solid #aa1039;
    text-align: left;
    display: flex;
    margin-bottom: -3px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  #bigNumber {
    position: static;
    font-weight: 600;
    font-size: 3em;
    margin-top: 2px;
    margin-bottom: 2px;
    padding-right: 20px;
  }
  
  #textLifter .undangan {
    text-align: center;
    text-transform: uppercase;
    top: 12%;
    font-size: 1.25em;
    font-weight: 600;
  }

  #textLifter .location {
    font-family: 'Rakkas';
    font-size: 1.5em;
    top: 24%;
  }

  #buttons {
    margin-top: 1em;
  }

  #buttons > div {
    position: static
  }

  h1 {
    position: absolute;
    text-align: center;
    top: 16.5%;
    font-family: 'Rakkas';
    text-align: center;
  }

  #place {
    text-align: center;
    position: absolute;
    font-weight: 600;
    top: 70%;
    width: 100%;
    transform-origin: 50%;
  }

  .centered {
    transform: translate(-50%, -50%);
  }
  
  #bell {
    top: 37.5%;
    left: 50%;
    width: 100%;
  }

  #wrapper {
    width: 100%;
    height: 100%;
    min-height: 554px;
    background-image: url('./assets/images/bg.jpg');
    background-repeat: repeat;
  }

  .snowflakes {
    top: 0;
    width: 100%;
  }

  #snowflake1 {
    left: 0;
    transform-origin: top left;
  }

  #snowflake2 {
    right: 0;
    transform-origin: top right;
  }

  #bottomLeft {
    left: 0;
    transform-origin: bottom left;
  }

  #bottomRight {
    right: 0;
    transform-origin: bottom right;
  }
  
  .bottom-image {
    bottom: 0;
    position: absolute;
    width: 100%;
  }

  .zoom-from-top-left-enter-active {
    animation: slide-from-top-left 4s;
    animation-timing-function: ease-out;
  }

  .zoom-from-top-right-enter-active {
    animation: slide-from-top-right 4s;
    animation-timing-function: ease-out;
  }

  .zoom-from-bottom-left-enter-active {
    animation: slide-from-bottom-left 4s;
    animation-timing-function: ease-out;
  }

  .zoom-from-bottom-right-enter-active {
    animation: slide-from-bottom-right 4s;
    animation-timing-function: ease-out;
  }

  .text-animation-enter-active {
    animation: text-animation 0.45s;
  }

  @keyframes text-animation {
    0% {
      transform: scale(0.5);
      color: #aa103900;
    }
    100% {
      transform: scale(1);
      color: #aa1039
    }
  }

  @keyframes slide-from-top-left {
    0% {
      transform: translate(-5%, -5%) scale(0.85);
    }
    100% {
      transform: translate(0, 0) scale(1)
    }
  }
  @keyframes slide-from-top-right {
    0% {
      transform: translate(5%, -5%) scale(0.85);
    }
    100% {
      transform: translate(0, 0) scale(1)
    }
  }
  @keyframes slide-from-bottom-left {
    0% {
      transform: translate(-5%, 5%) scale(0.85);
    }
    100% {
      transform: translate(0, 0) scale(1);
    }
  }
  @keyframes slide-from-bottom-right {
    0% {
      transform: translate(5%, 5%) scale(0.85);
    }
    100% {
      transform: translate(0, 0) scale(1);
    }
  }
</style>
